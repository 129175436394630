import styled from "styled-components";
import {Link} from "@mui/material";

const StyledCustomMenuUl = styled.ul`
  list-style: none;
  display: flex;
  justify-content: ${p => p.direction !== `vertical` && p.align === `right` ? `flex-end` : `flex-start`};
  align-items: ${p => p.direction === `vertical` && p.align === `bottom` ? `flex-end` : `flex-start`};
  margin: 0;
  padding: 0;
  flex-direction: ${p => p.direction === `vertical` ? `column` : `row`};
`
export default function CustomMenu({items, align = `left`, direction = `horizontal`}) {
  return (
    <StyledCustomMenuUl align={align} direction={direction}>
      {items.map((item, index) => (
        <li key={index}>
          <Link variant={`body2`} underline={`none`} href={item.href}>
            {item.label}
          </Link>
        </li>
      ))}
    </StyledCustomMenuUl>
  )
}
