import styled from "styled-components";
import {theme} from "../../themes/naloxoneRightNow";

const StyledFormActions = styled.div`
  border-top: 2px solid #E3E3E3;
  padding: 40px 0;
  text-align: right;
`

export default function FormActions({children}) {
  return (
    <StyledFormActions>
      {children}
    </StyledFormActions>
  )
}