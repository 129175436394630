import FormStep from "../form/FormStep";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input, Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import FormActions from "../form/FormActions";
import FormWrap from "../form/FormWrap";
import FormFieldsWrap from "../form/FormFieldsWrap";

export default function Step4() {
  return (
    <FormWrap>
      <FormStep label={`Step 4`}>

        <FormFieldsWrap>
          <FormControl fullWidth>
            <FormLabel id={`history-label`} htmlFor={`history`}>
              Does the potential recipient currently use or has a history of using illicit or prescription opioids?
            </FormLabel>
            <RadioGroup aria-labelledby={`history-label`} name={`history`} row>
              <FormControlLabel value={`yes`} control={<Radio disableRipple />} label={`Yes`} />
              <FormControlLabel value={`no`} control={<Radio disableRipple />} label={`No`} />
            </RadioGroup>
          </FormControl>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormControl fullWidth>
            <FormLabel id={`hypersensitivity-label`} htmlFor={`hypersensitivity`}>
              Does the person to whom the Narcan would be administered have a known hypersensitivity to Narcan?
            </FormLabel>
            <RadioGroup aria-labelledby={`hypersensitivity-label`} name={`hypersensitivity`} row>
              <FormControlLabel value={`yes`} control={<Radio disableRipple />} label={`Yes`} />
              <FormControlLabel value={`no`} control={<Radio disableRipple />} label={`No`} />
            </RadioGroup>
          </FormControl>
        </FormFieldsWrap>

      </FormStep>

      <FormActions>
        <FormControl fullWidth>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Button color={`inherit`} variant={`contained`} disableRipple>
              Previous
            </Button>
            <Button color={`secondary`} variant={`contained`} disableRipple>
              Next
            </Button>
          </Stack>
        </FormControl>
      </FormActions>
    </FormWrap>
  );
}