import {
  Container,
  Typography
} from "@mui/material";
import styled from "styled-components";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step0 from "./steps/Step0";
import {theme} from "../themes/naloxoneRightNow";

const StyledContentWrap = styled.main`
  padding: 80px 0;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 40px 0;
  }
`

export default function Content() {
  return (
    <Container>
      <StyledContentWrap>

        <Typography variant={`h1`} gutterBottom>
          Heading One
        </Typography>

        <Typography variant={`h2`} gutterBottom>
          Heading Two
        </Typography>

        <Typography variant={`h3`} gutterBottom>
          Heading Three
        </Typography>

        <Typography variant={`h4`} gutterBottom>
          Heading Four
        </Typography>

        <Typography variant={`h5`} gutterBottom>
          Heading Five
        </Typography>

        <Typography variant={`h6`} gutterBottom>
          Heading Six
        </Typography>

        <Typography variant={`subtitle1`} gutterBottom>
          Subtitle One
        </Typography>

        <Typography variant={`subtitle2`} gutterBottom>
          Subtitle Two
        </Typography>

        <Typography variant={`body1`} gutterBottom>
          <b>Body one paragraph</b> - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus elit. Donec egestas volutpat metus id ultricies. Proin tellus erat, interdum a luctus et, molestie vel lectus. Vestibulum et mollis velit, commodo ullamcorper urna. Sed quis lorem id metus accumsan convallis. Praesent varius lobortis vestibulum. Curabitur fringilla mattis elementum. Praesent nec nulla sed odio fringilla convallis quis in dolor. Maecenas non diam rutrum, ultrices risus sit amet, sodales quam. Sed fringilla sem sed tortor consequat euismod. Phasellus sit amet erat vitae quam finibus fringilla eget ut lorem.
        </Typography>

        <Typography variant={`body2`} gutterBottom>
          <b>Body two paragraph</b> - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus elit. Donec egestas volutpat metus id ultricies. Proin tellus erat, interdum a luctus et, molestie vel lectus. Vestibulum et mollis velit, commodo ullamcorper urna. Sed quis lorem id metus accumsan convallis. Praesent varius lobortis vestibulum. Curabitur fringilla mattis elementum. Praesent nec nulla sed odio fringilla convallis quis in dolor. Maecenas non diam rutrum, ultrices risus sit amet, sodales quam. Sed fringilla sem sed tortor consequat euismod. Phasellus sit amet erat vitae quam finibus fringilla eget ut lorem.
        </Typography>

        <Typography variant={`h2`} gutterBottom>
          Save a life! Carry Naloxone.
        </Typography>
        <Step0 />
        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
        <Step5 />

      </StyledContentWrap>
    </Container>
  );
}
