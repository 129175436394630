import FormStep from "../form/FormStep";
import {Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, Select, Stack} from "@mui/material";
import FormActions from "../form/FormActions";
import FormWrap from "../form/FormWrap";
import FormFieldsWrap from "../form/FormFieldsWrap";
import RadioGroupWithPills from "../form/RadioGroupWithPills";

export default function Step2() {
  return (
    <FormWrap>
      <FormStep label={`Step 2`}>

        <FormFieldsWrap>
          <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth>
                <FormLabel id={`shipping-state-label`} htmlFor={`shipping-state`}>
                  Please select your shipping state
                </FormLabel>
                <Select labelId={`shipping-state-label`} id={`shipping-state`} defaultValue={`KY`}>
                  <MenuItem value={`KY`}>Kentucky</MenuItem>
                  <MenuItem value={`CA`}>California</MenuItem>
                  <MenuItem value={`FL`}>Florida</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormControl fullWidth>
            <Grid container rowSpacing={4} columnSpacing={8}>
              <Grid item xs={12} sm={8}>
                <FormLabel id={`receive-label`} htmlFor={`receive`}>I would like to receive:</FormLabel>
                <RadioGroupWithPills aria-labelledby={`receive-label`} name={`receive`}>
                  <FormControlLabel value={`val1`} control={<Radio />} label={`Narcan Nasal Liquid 4MG`} />
                  <FormControlLabel value={`val2`} control={<Radio />} label={`Kloxxado Nasal Liquid 8MG`} />
                </RadioGroupWithPills>
              </Grid>
            </Grid>
          </FormControl>
        </FormFieldsWrap>

      </FormStep>
      <FormActions>
        <FormControl fullWidth>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Button color={`inherit`} variant={`contained`} disableRipple>
              Previous
            </Button>
            <Button color={`secondary`} variant={`contained`} disableRipple>
              Next
            </Button>
          </Stack>
        </FormControl>
      </FormActions>
    </FormWrap>
  );
}