import {
  Box,
  Button,
  FormControl,
  Typography,
} from "@mui/material";
import FormActions from "../form/FormActions";
import FormWrap from "../form/FormWrap";

export default function Step0() {
  return (
    <FormWrap>
      <Box>
        <Typography variant={`body1`} gutterBottom>
          The United States continues to experience a public health crisis of opioid-related harm. Overdose is currently one of the leading causes of death for Americans under 50 years old and is contributing to a decline in national life expectancy. To attack this epidemic, federal, state, and local governments have sought effective means to reduce the opioid-related harm.
        </Typography>
        <Typography variant={`body1`} gutterBottom>
          As of June 2019, officials in 29 states have issued Statewide Standing Orders which allow for licensed pharmacist to dispense Narcan without the recipient visiting a doctor. In total, 34 states, including Washington D.C. have provided pharmacy access to Narcan either through standing orders or through other statewide means.
        </Typography>
        <Typography variant={`body1`} gutterBottom>
          Nufactor is passionate about making this life saving drug available to individuals at risk, friends and family members and other individuals interested in carrying Narcan.
        </Typography>
        <Typography variant={`body1`} gutterBottom>
          A person who administers Narcan should do so in the good faith belief that the person receiving the Narcan is experiencing an overdose while they exercise reasonable care, including receiving instruction on how to use Narcan.
        </Typography>
        <Typography variant={`body1`} gutterBottom>
          <b>Please proceed to the next page to access this life saving medication.</b>
        </Typography>
      </Box>

      <FormActions>
        <FormControl>
          <Button color={`secondary`} variant={`contained`} disableRipple>
            Next
          </Button>
        </FormControl>
      </FormActions>
    </FormWrap>
  );
}