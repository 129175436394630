import FormStep from "../form/FormStep";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem, Radio,
  Select,
  Stack,
} from "@mui/material";
import FormActions from "../form/FormActions";
import FormWrap from "../form/FormWrap";
import FormFieldsWrap from "../form/FormFieldsWrap";
import RadioGroupWithPills from "../form/RadioGroupWithPills";

export default function Step3() {
  return (
    <FormWrap>
      <FormStep label={`Step 3`}>

        <FormFieldsWrap>
          <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel htmlFor={`shipping-state`}>
                  Please select your shipping state
                </FormLabel>
                <Select labelId={`shipping-state`} id={`shipping-state`} defaultValue={`KY`}>
                  <MenuItem value={`KY`}>Kentucky</MenuItem>
                  <MenuItem value={`CA`}>California</MenuItem>
                  <MenuItem value={`FL`}>Florida</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormControl fullWidth>
            <FormLabel id={`reason-label`} htmlFor={`reason`}>Why are you requesting Narcan?</FormLabel>
            <Grid container rowSpacing={4} columnSpacing={8}>
              <Grid item xs={12} sm={10}>
                <RadioGroupWithPills aria-labelledby={`reason-label`} name={`reason`}>
                  <FormControlLabel value={`val1`} control={<Radio />} label={`I am an individual at risk of experiencing an opioid-related overdose`} />
                  <FormControlLabel value={`val2`} control={<Radio />} label={`I am a family member, friend or other individual in a position to assist an individual at risk`} />
                </RadioGroupWithPills>
              </Grid>
            </Grid>
          </FormControl>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormControl fullWidth>
            <FormLabel id={`refill-reason-label`} htmlFor={`refill-reason`}>If you are requesting a refill, please select one of the following reasons:</FormLabel>
            <Grid container rowSpacing={4} columnSpacing={8}>
              <Grid item xs={12} sm={6}>
                <RadioGroupWithPills aria-labelledby={`refill-reason-label`} name={`refill-reason`}>
                  <FormControlLabel value={`val1`} control={<Radio />} label={`Used`} />
                  <FormControlLabel value={`val2`} control={<Radio />} label={`Expired`} />
                  <FormControlLabel value={`val3`} control={<Radio />} label={`Lost`} />
                  <FormControlLabel value={`val4`} control={<Radio />} label={`Damaged`} />
                </RadioGroupWithPills>
              </Grid>
            </Grid>
          </FormControl>
        </FormFieldsWrap>

      </FormStep>
      <FormActions>
        <FormControl fullWidth>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Button color={`inherit`} variant={`contained`} disableRipple>
              Previous
            </Button>
            <Button color={`secondary`} variant={`contained`} disableRipple>
              Next
            </Button>
          </Stack>
        </FormControl>
      </FormActions>
    </FormWrap>
  );
}