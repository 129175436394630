import styled from "styled-components";
import {Typography} from "@mui/material";
import {theme} from "../../themes/naloxoneRightNow";

const StyledFormStep = styled.div`
  min-height: 580px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    min-height: 0;
  }
`
const StyledFormStepContent = styled.div`
  padding: 52px 0 52px 90px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 32px 0;
  }
`

export default function FormStep({children, label}) {
  return (
    <StyledFormStep>
      <Typography variant={`subtitle1`}>
        {label}
      </Typography>
      <StyledFormStepContent>
        {children}
      </StyledFormStepContent>
    </StyledFormStep>
  )
}
