import * as React from "react";
import styled from "styled-components";
import {theme} from "../themes/naloxoneRightNow";
import CustomMenu from "./CustomMenu";
import logo from "../assets/logo.svg";
import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

const horizontalPadding = 32;

const StyledLogoWrap = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 240px;
    height: auto;
    vertical-align: top;
  }
`

const StyledMenuWrap = styled.nav`
  li {
    list-style: none;
  }
  li + li {
    margin-left: 32px;
  }
  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`
const StyledHeaderTop = styled(StyledMenuWrap)`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.secondary.contrastText};
  text-align: right;
  padding: 12px ${horizontalPadding}px 12px 0;
  font-size: ${theme.typography.headerTop.fontSize};
`
const StyledMobileHeaderTop = styled(StyledHeaderTop)`
  font-size: 16px;
  padding-left: ${horizontalPadding}px;
  display: flex;
  align-items: center;
  justify-content: center;
  li + li {
    margin-left: 24px;
  }
`
const StyledPrimaryMenuWrap = styled(StyledMenuWrap)`
  li + li {
    margin-left: 48px;
  }
`

const StyledHeaderBottom = styled.div`
  color: ${theme.palette.primary.main};
  font-size: ${theme.typography.headerBottom.fontSize};
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  height: 89px;
  align-items: center;
  justify-content: space-between;
  padding-left: ${horizontalPadding}px;
  padding-right: ${horizontalPadding}px;
`

const StyledHeader = styled.header`
  border-bottom: 2px solid ${theme.palette.header.bottomBorder};
`
export default function Header() {
  const secondaryMenuItems = [
    {label: `Track My Shipment`, href: `#`},
    {label: `Naloxone Blog`, href: `#`},
    {label: `Contact`, href: `#`},
  ];

  const primaryMenuItems = [
    {label: `Opioids`, href: `#`},
    {label: `Training`, href: `#`},
    {label: `Resources`, href: `#`},
    {label: `About Us`, href: `#`},
  ];

  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setMobileMenuAnchor(null);
  };

  return (
    <StyledHeader>

      {/* Desktop. */}
      <Box sx={{display: {xs: `none`, md: `block`}}}>
        <StyledHeaderTop>
          <CustomMenu items={secondaryMenuItems} align={`right`} />
        </StyledHeaderTop>
      </Box>

      {/* Mobile. */}
      <Box sx={{display: {xs: `block`, md: `none`}}}>
        <StyledMobileHeaderTop>
          <CustomMenu items={secondaryMenuItems} align={`right`} />
        </StyledMobileHeaderTop>
      </Box>

      <StyledHeaderBottom>
        <AppBar position={`static`} elevation={0}>
          <Toolbar disableGutters>

            <Stack direction={`row`} justifyContent={`space-between`} sx={{flex: 1}}>
              <StyledLogoWrap>
                <Link href={`#`}><img src={logo} alt={`Naloxone Right Now logo`} /></Link>
              </StyledLogoWrap>
              {/* Mobile menu. */}
              <Box sx={{display: {xs: `block`, lg: `none`}, position: `relative`}}>
                <IconButton
                  size={`large`}
                  aria-label={`Open mobile menu`}
                  aria-controls={`menu-appbar`}
                  aria-haspopup={true}
                  onClick={handleOpenNavMenu}
                  color={`inherit`}
                  disableRipple
                  sx={{borderRadius: 0}}
                >
                  <FontAwesomeIcon icon={faBars} color={`#000`} />
                </IconButton>
                <Menu id={`menu-primary-mobile`} open={Boolean(mobileMenuAnchor)} keepMounted anchorEl={mobileMenuAnchor} anchorOrigin={{vertical: `bottom`, horizontal: `left`}} onClose={handleCloseNavMenu}>
                  {primaryMenuItems.map((item, index) => (
                    <MenuItem key={index} onClick={handleCloseNavMenu} href={item.href}>
                      <Typography variant={`body1`}>{item.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/* Desktop menu. */}
              <Box sx={{display: {xs: `none`, lg: `flex`}, fontWeight: 600, alignItems: `center`}}>
                <StyledPrimaryMenuWrap>
                  <CustomMenu items={primaryMenuItems} />
                </StyledPrimaryMenuWrap>
              </Box>
            </Stack>

          </Toolbar>
        </AppBar>
      </StyledHeaderBottom>

    </StyledHeader>
  );
}
