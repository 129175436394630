import Header from "./components/Header";
import Footer from "./components/Footer";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./themes/naloxoneRightNow";
import Content from "./components/Content";

function App() {
  return (
    <ThemeProvider theme={theme}>

      <CssBaseline />

      <Header />
      <Content />
      <Footer />

    </ThemeProvider>
  );
}

export default App;
