import FormStep from "../form/FormStep";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio, Stack,
} from "@mui/material";
import FormActions from "../form/FormActions";
import FormWrap from "../form/FormWrap";
import FormFieldsWrap from "../form/FormFieldsWrap";
import RadioGroupWithPills from "../form/RadioGroupWithPills";

export default function Step1() {
  return (
    <FormWrap>
      <FormStep label={`Step 1`}>
        <FormControl fullWidth>

          <FormFieldsWrap>
            <FormControl fullWidth>
              <Grid container rowSpacing={4} columnSpacing={8}>
                <Grid item xs={12} sm={8}>
                  <FormLabel id={`interest-label`} htmlFor={`interest`}>I am interested in Naloxone as an:</FormLabel>
                  <RadioGroupWithPills aria-labelledby={`interest-label`} name={`interest`}>
                    <FormControlLabel value={`val1`} control={<Radio />} label={`Individual`} />
                    <FormControlLabel value={`val2`} control={<Radio />} label={`Organization`} />
                  </RadioGroupWithPills>
                </Grid>
              </Grid>
            </FormControl>
          </FormFieldsWrap>

        </FormControl>
      </FormStep>

      <FormActions>
        <FormControl fullWidth>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Button color={`inherit`} variant={`contained`} disableRipple>
              Previous
            </Button>
            <Button color={`secondary`} variant={`contained`} disableRipple>
              Next
            </Button>
          </Stack>
        </FormControl>
      </FormActions>
    </FormWrap>
  );
}