import {RadioGroup, styled} from "@mui/material";
import {paddings} from "../../themes/naloxoneRightNow";

const StyledRadioGroup = styled((props) => (
  <RadioGroup {...props} />
))(() => ({
  '.MuiButtonBase-root': {
    display: `none`,
  },
  '.MuiFormControlLabel-label': {
    display: `block`,
    borderRadius: `5px`,
    border: `2px solid #E3E3E3`,
    fontSize: `20px`,
    lineHeight: `20px`,
    padding: `17px ${paddings.inputIndent}px 20px ${paddings.inputIndent}px`,
    height: `64px`,
    width: `100%`,
  },
  '.MuiFormControlLabel-root': {
    marginLeft: 0,
    marginRight: 0,
    lineHeight: 1,
  },
  '.MuiFormControlLabel-root + .MuiFormControlLabel-root': {
    marginTop: `32px`,
  },
  '.MuiFormControlLabel-label:hover': {
    background: `rgba(19, 50, 87, 0.04)`,
  },
  '.Mui-checked.MuiRadio-root + .MuiFormControlLabel-label': {
    border: `2px solid #133257`,
    background: `rgba(19, 50, 87, 0.04)`,
  },
}));

export default function RadioGroupWithPills({children}) {
  return (
    <StyledRadioGroup>
      {children}
    </StyledRadioGroup>
  )
}