import SegoeUiWoff from "../assets/fonts/Segoe-UI.woff";
import SegoeUiBoldWoff from "../assets/fonts/Segoe-UI-Bold.woff";
import SegoeUiBoldItalicWoff from "../assets/fonts/Segoe-UI-Bold-Italic.woff";
import SegoeUiItalicWoff from "../assets/fonts/Segoe-UI-Italic.woff";

const fonts = {
  segoeUi: {
    regular: {
      fontFamily: `'Segoe UI Regular', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `normal`,
      fontWeight: 400,
      src: `
        local('Segoe UI Regular'),
        url(${SegoeUiWoff}) format('woff')
      `,
    },
    bold: {
      fontFamily: `'Segoe UI Bold', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `normal`,
      fontWeight: 600,
      src: `
        local('Segoe UI Bold'),
        url(${SegoeUiBoldWoff}) format('woff')
      `,
    },
    boldItalic: {
      fontFamily: `'Segoe UI Bold Italic', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `italic`,
      fontWeight: 600,
      src: `
        local('Segoe UI Bold Italic'),
        url(${SegoeUiBoldItalicWoff}) format('woff')
      `,
    },
    italic: {
      fontFamily: `'Segoe UI Italic', 'Helvetica Neue', 'sans-serif'`,
      fontStyle: `italic`,
      fontWeight: 400,
      src: `
        local('Segoe UI Italic'),
        url(${SegoeUiItalicWoff}) format('woff')
      `,
    },
  },
}

export default fonts;
