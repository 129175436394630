import styled from "styled-components";
import {margins} from "../../themes/naloxoneRightNow";

const StyledFormWrap = styled.div`
  margin-bottom: ${margins.gutterBottom *2}px;
`

export default function FormWrap({children}) {
  return (
    <StyledFormWrap>
      {children}
    </StyledFormWrap>
  )
}