import * as React from 'react';
import FormStep from "../form/FormStep";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel, MenuItem, OutlinedInput, Select,
  Stack, TextField, Typography
} from "@mui/material";
import FormActions from "../form/FormActions";
import FormWrap from "../form/FormWrap";
import FormFieldsWrap from "../form/FormFieldsWrap";
import {MobileDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SelectFormControl from "../form/SelectFormControl";

export default function Step5() {
  const [value, setValue] = React.useState(
    dayjs('2014-08-18T21:11:54'),
  );

  const handleChange = newValue => setValue(newValue);

  return (
    <FormWrap>
      <FormStep label={`Step 5`}>

        <FormFieldsWrap>
          <FormLabel>Recipient</FormLabel>
          <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField label={`First Name`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`Last Name`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
          </Grid>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormLabel>Shipping Information</FormLabel>
          <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField label={`Address`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`Address 2`} fullWidth InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`City`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField label={`State`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField label={`Zip Code`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFormControl variant={`outlined`} fullWidth>
                <InputLabel id={`address-type-label`} htmlFor={`address-type`} disableAnimation>Address Type</InputLabel>
                <Select labelId={`address-type-label`} id={`address-type`} input={<OutlinedInput notched label={`Address Type`} />} defaultValue={``}>
                  <MenuItem value={`address-type-1`}>Address type 1</MenuItem>
                  <MenuItem value={`address-type-2`}>Address type 2</MenuItem>
                </Select>
              </SelectFormControl>
            </Grid>
          </Grid>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormControl fullWidth>
            <FormControlLabel control={<Checkbox />} label={`Please select the box if the shipping and recipient address are the same.`} />
          </FormControl>
        </FormFieldsWrap>

        <FormFieldsWrap>
          <FormLabel >Recipient Information</FormLabel>
          <Grid container rowSpacing={4} columnSpacing={8}>
            <Grid item xs={12} sm={6}>
              <TextField label={`Address`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`Address 2`} fullWidth InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`City`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField label={`State`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField label={`Zip Code`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`Email`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label={`Phone`} fullWidth required InputLabelProps={{disableAnimation: true}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFormControl fullWidth>
                <InputLabel id={`gender-label`} htmlFor={`gender`} disableAnimation>Gender *</InputLabel>
                <Select labelId={`gender-label`} id={`gender`} defaultValue={``}>
                  <MenuItem value={`male`}>Male</MenuItem>
                  <MenuItem value={`female`}>Female</MenuItem>
                </Select>
              </SelectFormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label={`Date of Birth *`}
                  inputFormat={`MM/DD/YYYY`}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={handleChange}
                  value={value}/>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={`body1`}>[captcha]</Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert severity={`error`}>
                Fields marked with asterisk (*) are required. Please provide all required values to enable the 'Next' button.
              </Alert>
            </Grid>
          </Grid>
        </FormFieldsWrap>

      </FormStep>
      <FormActions>
        <FormControl fullWidth>
          <Stack direction={`row`} justifyContent={`space-between`}>
            <Button color={`inherit`} variant={`contained`} disableRipple>
              Previous
            </Button>
            <Button color={`secondary`} variant={`contained`} disableRipple>
              Next
            </Button>
          </Stack>
        </FormControl>
      </FormActions>
    </FormWrap>
  );
}